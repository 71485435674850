import {Input} from "antd";
import {useEffect, useRef} from "react";
import locationArrow from "../assets/location-marker.svg";
import {iconDown, iconUp} from "../static/styledIcons/styledIcons";
import {useUIActions} from "../store/useActions";

const {TextArea} = Input;

const Navbar = ({children, opened, setOpened, getCurrentPosition, ...props}) => {
    const { setTutorialRun } = useUIActions();
    const hasCalledGetCurrentPosition = useRef(false);

    useEffect(() => {
        // Отложенный вызов функции getCurrentPosition через 5 секунд после загрузки страницы
        if (typeof getCurrentPosition === 'function' && !hasCalledGetCurrentPosition.current) {
            const timer = setTimeout(() => {
                getCurrentPosition();
                hasCalledGetCurrentPosition.current = true;
            }, 250); // 1000 миллисекунд = 1 секунд

            // Очистка таймера при размонтировании компонента или изменении зависимости
            return () => clearTimeout(timer);
        } else if (typeof getCurrentPosition !== 'function') {
            console.error("getCurrentPosition is not a function");
        }
    }, [getCurrentPosition]); // Пустой массив зависимостей означает, что эффект выполнится только один раз при монтировании компонента

    const open = () => {
        setOpened(true);
    };

    const close = () => {
        setOpened(false);
    };

    const change = () => {
        if (opened) {
            close();
        } else {
            open();
        }
    };

    const style = {
        height: opened ? props.maxHeight : props.minHeight,
    };
    if (props.zIndex) {
        style.zIndex = props.zIndex;
    }

    return (
        <div
            className="banner"
            style={style}
            id={props.isInput ? "input-banner" : ""}
        >
            {props.isInput ? null : (
                <div
                    className="location-button--wrapper"
                    onClick={() => {
                        setTutorialRun(false);
                        if (typeof getCurrentPosition === 'function') {
                            getCurrentPosition();
                        } else {
                            console.error("getCurrentPosition is not a function");
                        }
                    }}
                >
                    <img src={locationArrow} alt="location-arrow" className="location-button--icon"/>
                </div>
            )}
            <div className="banner__top">
                <span className="banner__top-wrap" onClick={() => change()}>
                    {opened ? iconDown : iconUp}
                </span>
            </div>
            <div className="banner__content">
                <div className="banner__content-menu">{children}</div>
            </div>
        </div>
    );
};

export default Navbar;