import axios from "axios";
import { NOMINATIM_URL } from "../const";
import debounce from 'lodash.debounce';

const NominatimClient = axios.create({
    baseURL: NOMINATIM_URL,
    // headers: {
    //     'Access-Control-Allow-Origin': '*'
    // }
});

// Значения по умолчанию
let REGION = "Дальневосточный федеральный округ";
let STATE  = "Амурская область";
let INITIAL_COORDS = null; // Изначально пустое значение
let currentAbortController = null; // Контроллер для отмены запроса

// Функция для получения текущего местоположения пользователя
const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

// Функция для обновления REGION и STATE на основе текущих координат
const updateLocationBasedOnCoordinates = async () => {
    try {
        const position = await getCurrentLocation();
        INITIAL_COORDS = position.coords; // Сохраняем начальные координаты
        const response = await nominatimReverse(position.coords.latitude, position.coords.longitude);
        const address = response.data.address;
        if (address.region) {
            REGION = address.region;
        }
        if (address.state) {
            STATE = address.state;
        }
    } catch (error) {
        console.error("Error updating location based on coordinates:", error);
    }
};

// Инициализация при загрузке страницы
const initializeLocation = async () => {
    await updateLocationBasedOnCoordinates();
    // Добавляем обработчик ввода только после инициализации
    document.getElementById('address-input').addEventListener('input', (event) => {
        handleInputChange(event.target.value);
    });
};

// Вызов инициализации при загрузке страницы
document.addEventListener('DOMContentLoaded', initializeLocation);

// Функция для создания viewbox на основе координат и радиуса в километрах
const createViewbox = (latitude, longitude, radiusKm) => {
    const delta = radiusKm / 111; // Примерно 1 градус = 111 километров
    const minLat = latitude - delta;
    const maxLat = latitude + delta;
    const minLon = longitude - delta;
    const maxLon = longitude + delta;
    return `${minLon},${minLat},${maxLon},${maxLat}`;
};

// Функция для задержки на заданное количество миллисекунд
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const nominatimSearch = async query => {
    if (currentAbortController) {
        currentAbortController.abort(); // Отменяем предыдущий запрос, если он существует
    }

    currentAbortController = new AbortController(); // Создаем новый AbortController
    const { signal } = currentAbortController;

    const radii = [15, 30, 60, 120, 240, 480, 960, 1920]; // Этапы радиусов в километрах
    let response = { data: [] };

    for (const radius of radii) {
        const searchParams = new URLSearchParams({
            q: `${REGION} ${STATE} ${query}`,
            addressdetails: "1",
            format: "jsonv2"
        });

        if (INITIAL_COORDS) {
            const viewbox = createViewbox(INITIAL_COORDS.latitude, INITIAL_COORDS.longitude, radius);
            searchParams.append("viewbox", viewbox);
            searchParams.append("bounded", "1");
        }

        try {
            response = await NominatimClient.get(`/search?${searchParams.toString()}`, { signal });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
                return; // Прекращаем выполнение, если запрос был отменен
            } else {
                console.error(`Error during search with radius ${radius} km:`, error);
            }
        }

        if (response.data.length > 0) {
            break; // Останавливаем поиск, если адрес найден
        }

        await delay(250); // Пауза в 0,25 секунды перед следующим этапом
    }

    return response;
};

export const nominatimReverse = (latitude, longitude) => {
    return NominatimClient.get(
        `/reverse?lat=${latitude}&lon=${longitude}&addressdetails=1&format=json`
    );
};

// Функция для обработки ввода пользователя с использованием debounce
const handleInputChange = debounce(async (query) => {
    const response = await nominatimSearch(query);
    // Обработка результатов поиска
    console.log(response.data);
}, 150); // Задержка в 150 миллисекунд